@use '../../styles/global' as *;

.education{
    &__school{
        color: $desert-tertiary-offset-color;
        font-weight: 800;
        letter-spacing: .2rem;
        margin: 0;
        font-size: $header-mobile;

        @include tablet-adapt{
            font-size: $header-tablet;
        }
    };

    &__course{
        margin: 1% 0;
        font-size: $content-mobile;
        @include tablet-adapt{
            font-size: $content-tablet;
        }
    }
    
    &__dates{
        color: $desert-primary-color;
        margin: 0;
        font-size: $content-mobile;
        @include tablet-adapt{
            font-size: $content-tablet;
        }
    }

    &__wrapper{
        margin-bottom: 3.5%;

        &:nth-of-type(1){
            margin-bottom: 16px;
        }
    }
}