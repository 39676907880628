@use '../../styles/global' as *;

.interests{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: $desert-secondary-offset-color;
    height: 14vw;
    width: 100%;

    &__left{
        width: 42%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        &-top{
            width: 80%;
            max-width: 480px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-right: -5%;
        }
        
        &-bottom{
            width: 70%;
            max-width: 415px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    &__center{
        width: 26%;
        max-width: 300px;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
    }
    
    &__right{
        width: 42%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        

        &-top{
            width: 80%;
            max-width: 480px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        
        &-bottom{
            width: 70%;
            max-width: 415px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    &__icon{
        height: 5vw;
        width: 5vw;
        max-height: 75px;
        max-width: 75px;
        position: relative;
        z-index: 1;
        
        &:nth-of-type(odd) {
            top: 1vw;
        }

        &:nth-of-type(even) {
            bottom: .5vw;
        }

        &-wine {
            margin-top: -.5vw;
        }

        &-laptop {
            height: 7vw;
            width: 7vw;
            position: relative;
            top: 0;
        }

        &-football{
            position: relative;
            left: 2.5vw;
        }

        &-ffvii{
            position: relative;
            right: 2vw;
            height: 4.5vw;
            width: 4.5vw;            
        }

        &-autobot{
            height: 4vw;
            width: 4vw;
        }

        &-weights{
            position: relative;
            left: 0.5vw;
        }

        &-beach {
            height: 7vw;
            width: 7vw;
        }

        &-thought {
            height: 4vw;
            width: 4vw;
            position: relative;
            margin-top:-1vw; 
        }

        &-lightbulb{
            height: 4vw;
            width: 4vw;
        }

        &-javascript{
            height: 4vw;
            width: 4vw;
            margin-top: -1.5vw;
        }

        &-brain{
            position: relative;
            top: 1vw;
        }

        &-com{
            margin-top: -1vw;
        }

        &-book {
            left: 2vw;
        }
    }
}