@use '../../styles/global' as *;

.video-modal{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(63, 63, 63,0.8);
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  &__close{
    font-size: 2rem;
    color: #FFF;
    position: absolute;
    z-index: 1;
    top: 3vw;
    right: 3vw;
    margin: 0;

    &:hover{
      font-weight: 600;
      animation: color-fade-arrow--profile .5s linear forwards;
      cursor: pointer;
    }
  }

  &__video{
    width: 100%;
    max-width: 800px;
    height: fit-content;
  }
}