@use '../../styles/global' as *;

.learn-more {
    display: flex;

    &--left{
        justify-content: flex-end;    
    };

    &--right{
        justify-content: flex-start;    
    };

    &__link{
        text-decoration: none;
        color: $desert-tertiary-offset-color;
        font-weight: 800;
        font-size: .8125rem;
        letter-spacing: .2rem;
        display: flex;
        align-items: center;
    
        &:hover{
            cursor: pointer;
            animation: color-fade-arrow .7s linear forwards;
    
            & .learn-more__double-arrow{
                animation: float-right 1.4s linear infinite;
            }
            & .learn-more__double-arrow--reverse{
                animation: float-left 1.4s linear infinite;
            }
        }

        &--profile{
            color: #FFF;

            &:hover{
                animation: color-fade-arrow--profile .7s linear forwards;
                cursor: pointer;
            }
        }
    }
    &__double-arrow{
        height: 1.2rem;
        
        &--reverse{
            transform: scaleX(-1);
        }
    }
}