@use '../../styles/global' as *;

.welcome {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    &__roots{
        width: 66%;
        position: relative;
        right: 5px;
        bottom: 2px;
        
        @include desktop-adapt{
            width: 60%;
            max-width: 770px;
        }
    }
    
    &__banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    &__message{
        color: white;
        width: 60%;
        max-width: 770px;
        margin-bottom: 0;
        font-size: 1.5rem;
        @include desktop-adapt{
            font-size: 1rem;
        }

        &--emph{
            font-size: 3rem;
            @include desktop-adapt {
                font-size: 2rem;
            }
            color: $desert-primary-color;
        }
    }

    &__signature{
        width: 60%;
        max-width: 770px;
        margin-top: 1%;

        &-gif{
            align-self: flex-start;
            height: 70%;
            width: 35%;
            @include desktop-adapt{
                width: 20%;
            }
        }
    }

    &__arrow{
        animation: float 1s linear infinite;
        width: 3%;

        &:hover{
            cursor: pointer;
        }
        
        &-scroll{
            color: white;
            letter-spacing: .4rem;

            &:hover{
                cursor: pointer;
            }
        }
    }
}