@use '../../styles/global' as *;

.experience{
    &__current{
        margin: 0;
        font-size: $header-mobile;
    };

    &__employer{
        color: $desert-tertiary-offset-color;
        font-weight: 800;
        letter-spacing: .2rem;
        font-size: $header-mobile;

        @include tablet-adapt{
            font-size: $header-tablet;
        }
    };

    &__role{
        margin: 1% 0;
        font-size: $content-mobile;

        @include tablet-adapt{
            font-size: $content-tablet;
        }
    }

    &__dates{
        color: $desert-primary-color;
        margin: 0;
        font-size: $content-mobile;

        @include tablet-adapt{
            font-size: $content-tablet;
        }
    }

    &__responsibilities{
        color: $desert-primary-color;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 10%;
    
        @include desktop-adapt{
            padding-left: 5%;
        }
        
        &-heading{
            font-size: .9125rem;
            margin: 0 0 2%;
        }
        
        &-item{
            align-self: flex-end;
            color: #FFF;
            margin-bottom: 1%;
            font-size: $content-mobile;
            
            @include tablet-adapt{
                font-size: .9125rem;
            }

            &::marker{
                color: $desert-tertiary-offset-color;
            }
        }
    }
}