@use '../../styles/global' as *;

.profile{
    min-height: 70vh;
    max-width: 300px;
    background-color: $desert-primary-offset-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 26%;

    @include desktop-adapt{
        min-height: 100vh;
        width: 22%;
    }

    &--indepth{
        z-index: 2;
        animation: profile-trunk-expand 2s linear forwards;
        transform-origin: bottom;
        min-height: 100vh;

        @include tablet-adapt {
            min-height: 130vh;
        }

        @include desktop-adapt {
            min-height: 200vh;
        }
    }

    &__indepth-test{
        position: absolute;
    }
    
    &__image {
        width: 80%; 
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -9vw;
    }
    
    &__heading {
        width: 80%;
        margin: 45% 0 0;
        display: flex;
        flex-direction: column;

        @include desktop-adapt{
            margin-top: 35%;
        }

        &-first-name{
            margin-bottom: 0;
            letter-spacing: .3rem;
            font-weight: 800;
            text-align: center;
            font-size: 1.8rem;
            @include tablet-adapt{
                align-self: flex-start;
                font-size: 2.4rem;
            }
            @include desktop-adapt{
                align-self: flex-start;
                font-size: 3rem;
            }
        }
        
        &-last-name{
            margin: 0 0 2px;
            font-weight: 800;
            font-size: 1.8rem;
            text-align: center;
            @include tablet-adapt{
                align-self: flex-end;
                font-size: 2.4rem;
            }
            @include desktop-adapt{
                align-self: flex-end;
                font-size: 3rem;
            }
        }

        &-position{
            font-size: 1.3rem;
            font-style: italic;
            margin-top: 0;
            color: $desert-primary-color;
            text-align: center;
        }
    }

    &__overview{
        width: 80%;
        margin: 0;
        font-size: $content-tablet;
        text-align: center;
        padding-bottom: 10%;
    }

    &__contact{
        width: 80%;
        margin-top: 10%;
        padding: 3% 0;
        border-top: solid $desert-primary-color;
        border-bottom: solid $desert-primary-color;
        border-width: 2px 0;

        @include tablet-adapt {
            border-width: 4px 0;
        }

        &-method{
            margin: 10% 0;
            
            & > a {
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;
                font-size: $content-mobile;
            }
        }

        &-icon{
            width: 15%;
            margin-right: 5%;
        }
    }

    &__social{
        width: 80%;
        margin: 15% 0;

        &-icons{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-icon{
            width: 100%;

            &:hover{
                transform: translateY(-4px) rotateY(360deg);
                transition-duration: .6s;
            }
        }

        &-link {
            margin: 0 5%;
            width: 25%;
            text-decoration: none;
        }

        &-heading{
            color: white;
            margin: 10% 0;
            text-align: center;
        }
    }
}