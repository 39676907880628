@use '../../styles/global' as *;

.indepth {
    width: 250%;
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    position: relative;
    
    &-left{
        align-items: flex-start;
        right: 150%;
    }
    
    &-right{
        align-items: flex-start;
    }

    &__wrapper{
        position: relative;
        width: 100%;
    }
}