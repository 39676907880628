@import './variables';

@keyframes float {
    0% {transform: translateY(0)};
    50% {transform: translateY(25%)};
    100% {transform: translateY(0)};
}

@keyframes float-right {
    0% {transform: translateX(0)};
    50% {transform: translateX(25%)};
    100% {transform: translateX(0)};
}
@keyframes float-left {
    0% {transform: translateX(0) scaleX(-1)};
    50% {transform: translateX(-25%) scaleX(-1)};
    100% {transform: translateX(0) scaleX(-1)};
}

@keyframes color-fade-arrow {
    0% {color: $desert-tertiary-offset-color};
    100% {color: $desert-primary-color};
}

@keyframes color-fade-arrow--profile {
    0% {color: #FFF};
    100% {color: $desert-primary-color};
}

@keyframes color-fade-button {
    0% {background-color: $desert-secondary-offset-color};
    100% {background-color: $desert-primary-color};
}

@keyframes leaves-fade-in {
    0% {opacity: 0; }
    100% {opacity: 1;}
}

@keyframes branch-reveal{
    0% {
        stroke-dashoffset: -134.96;
    }
    90% {
        stroke-dashoffset: 0;
    }
}
@keyframes branch-reveal-two{
    0% {
        stroke-dashoffset: -174.96;
    }
    90% {
        stroke-dashoffset: 0;
    }
}

@keyframes branch-fade-out {
    0%{
        opacity: 1;
    }   
    100%{
        opacity: 0;
    }
}

@keyframes experience-enter {
    0%{
        transform: scaleX(0) scaleY(0);
    }
    50%{
        transform: scaleX(0) scaleY(1);
    }

    100%{
        transform: scaleX(1) scaleY(1);
    }
}

@keyframes quoteTyping {
    0%{
        color: rgba(255, 255, 255,0);
    }
    50%{
        color: rgba(255, 255, 255,1);
    }
    100%{
        color: $desert-primary-color;
    }
}

@keyframes cursor-flash {
    50%{
        color: rgba(255, 255, 255,1);
    }
}

@keyframes rotate-reveal {
    100%{
        transform: rotateZ(-180deg);
        z-index: 3;
    }
}

@keyframes logo-fade-in {
    100%{
        opacity: 1;
    }
}

@keyframes logo-fade-out {
    100%{
        opacity: 0;
    }
}

@keyframes logo-color-trans {
    100%{
        fill: $desert-primary-color
    }
}

@keyframes profile-trunk-expand {
    0%{
        transform: scaleX(1) scaleY(1);
    }
    50%{
        transform: scaleX(1) scaleY(1.15);
    }
    100%{
        transform: scaleX(8) scaleY(1.15);
    }
}

@keyframes map-route-reveal{
    0% {
        stroke-dashoffset: 578.765380859375;
    }
    90% {
        stroke-dashoffset: 0;
    }
}

@keyframes plant-growth{
    0% {
        stroke-dashoffset: 121;
    }
    90% {
        stroke-dashoffset: 60;
    }
}
@keyframes plant-growth-upper{
    0% {
        stroke-dashoffset: 20;
    }
    90% {
        stroke-dashoffset: 10
    }
}

@keyframes plant-growth-curl{
    0% {
        stroke-dashoffset: -11.7;
    }
    90% {
        stroke-dashoffset: 0;
    }
}

@keyframes spinner {
    0% {
        transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scale(1,1);
    }
    80% {
        transform: rotateY(1260deg) rotateX(540deg) rotateZ(540deg) scale(1,1);
    }
    90% {
        transform: rotateY(1260deg) rotateX(540deg) rotateZ(540deg) scale(.9,.9);
    }
    100% {
        transform: rotateY(1260deg) rotateX(540deg) rotateZ(540deg) scale(1,1);
    }
}
@keyframes load-fade {
    0% {fill: $desert-secondary-offset-color};
    50% {fill: $desert-primary-color};
    100% {fill: $desert-secondary-offset-color};
}
@keyframes welcome-fade {
    0% {fill: $desert-secondary-offset-color};
    100% {fill: $desert-primary-color};
}
@keyframes load-remove {
    0% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}