@use '../../styles/global' as *;

.skills{
  &__heading{
    margin: 0;
    font-size: $content-mobile;

    @include tablet-adapt{
      font-size: $content-tablet;
    }
  }

  &__list{
    padding-left: 5%;
    column-count: 2;
    width: 80%;
    margin: 4% 0;

    &-item{
      margin-bottom: 1%;
      font-size: $content-mobile;

      @include tablet-adapt{
        font-size: $content-tablet;
      }

      &::marker{
        color: $desert-tertiary-offset-color;
      }
    }
  }

  &__caption{
    color: $desert-primary-color;
    font-size: $content-mobile;
    
    @include tablet-adapt{
      font-size: $content-tablet;
    }
  }
}