@use '../../styles/global' as *;

main {
    display: flex;
    justify-content: center;
    color: white;
    width: 100%;
}

.roots{
    display: flex;
    justify-content: center;
    color: white;
}

.appraisal{
    height: fit-content;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

    &__left{
        margin-top: 14vw;
        width: 39%;
        max-width: 40vw;
        @include tablet-adapt{
            max-width: 35vw;
        }
        @include desktop-large-adapt{
            margin-top: 10vw;
            max-width: 31vw;
        }
    }
    &__right{
        margin-top: 14vw;
        width: 39%;
        max-width: 40vw;
        @include tablet-adapt{
            max-width: 35vw;
        }
        @include desktop-large-adapt{
            margin-top: 10vw;
            max-width: 31vw;
        }
    }
}

.more-detail{
    position: absolute;
    width: 100vw;
    background-color: #FFF;

    &-left{
        left: 0;
        top: 0;
    }

    &-right{
        left: 200vw;
        top: 0;
    }
}

.wrapper{
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.width-control{
    width: 240vw;
    position: relative;
    height: 10px;
}