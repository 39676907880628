@use '../../styles/global' as *;

.experience-indepth{
    width: 100%;
    margin: 5% 0;
    min-height: 180vh;

    &__position-wrapper{
        display: flex;
        justify-content: space-between;
        animation: experience-enter .8s ease-in-out forwards;
        transform-origin: left;

        &:nth-of-type(even){
            flex-direction: row-reverse;
            transform-origin: right;
        }
    }

    &__date-range{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5%;
        color: $desert-primary-color;
    }
    
    &__position{
        &-details{
            padding: 5%;
            width: 65%;
            
            @include desktop-adapt{
                width: 50%;
            }
        }

        &-title{
            color: $desert-primary-color; 
        }

        &-description-list{
            padding-left: 5%;
            
            // @include tablet-adapt{
            //     padding-left: 15%;
            // }
        }
    }
}