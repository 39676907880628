@use '../../styles/global' as *;

.trait{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    max-height: 34%;

    &__heading-wrapper{
        display: inline;
        position: relative;

    }

    &__wrapper{
        padding: 0 5% 6%;
    }

    &--left{
        text-align: right;
    }

    &--inactive{
        display: none;
    }
}