@use '../../styles/global' as *;

.projects{
  &__heading{
    color: $desert-tertiary-offset-color;
    font-weight: 800;
    letter-spacing: .2rem;
    font-size: $header-mobile;
    margin: 0;

    @include tablet-adapt{
      font-size: $header-tablet;
    }
  };

  &__description{
    margin: 0 0 4%;
    font-size: $content-mobile;
    @include tablet-adapt{
      font-size: $content-tablet;
    }

    &-demo{
      color: $desert-primary-color;
      margin-left: 2%;
      font-size: $content-mobile;
      white-space: nowrap;

      @include tablet-adapt{
        font-size: $content-tablet;
      }

      &:hover{
        cursor: pointer;
        font-weight: 600;
      }
    }
  }

  &__name{
    color: #4bb69f;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    margin: 1% 0;

    &-logo{
      margin: 0 -3px;
      height: 2rem;
    }
  }

  &__links{
    display: flex;
    width: 80%;
    
    @include desktop-adapt{
      width: 60%;
    }
  }

  &__link{
    @include project-button;
  }
  &__wrapper{
    margin-bottom: 3.5%;
}
}