@import './partials/typography';
@import './partials/mixins';
@import './partials/animations';
@import './partials/variables';

// ---Global Reset 

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-x: hidden;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  // html {
  //  overflow: auto;
  // }
  
  *, *:before, *:after {
    box-sizing: inherit;
    scroll-behavior: inherit;
  }

// ---Re-usable Component styling---
